import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import ReactTooltip from 'react-tooltip';
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";

const ExploreCard = ({ module }) => {
  return (
    <>
      <section className={`zigzag explore_section lg:py-120 py-60 lg:my-60 my-30 ${module.extraClass === "" ? "" : module.extraClass
        }`}>
        <div className="container-fluid">
          <div className="title-green mb-50">
            <h2>{module.heading}</h2>
          </div>
          <div className="flex flex-wrap items-center">
            <div className="lg:w-6/12 w-full">
              <div className="image-wrapper">
                <GatsbyImage
                  image={getImage(module.image)}
                  alt={module.image.altText}
                  className="w-100"
                ></GatsbyImage>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              <div className="zigzag-content lg:max-w-450 lg:mx-auto pt-30 lg:py-30 lg:px-19 fade-ani">
                <div className="title-black mb-30 mt-30">
                  <h3>{module.title}</h3>
                </div>
                <div className="content">
                  {parse(module.descriptions)}
                  <div className="tooltip-icon flex space-x-5 py-10 mt-20">
                    {module.amenities.map((item, i) => {
                      return (
                        <Tooltip
                          placement="topLeft"
                          showArrow={false}
                          overlayInnerStyle={{
                            backgroundColor: "#787F3D",
                            color: "#fff",
                            border: "#787F3D",
                            borderRadius: "0px",
                            fontSize: '11px',
                            opacity: "100",
                          }}
                          overlay={
                            <div
                              overlayStyle={{
                                backgroundColor: "#787F3D",
                              }}
                            >
                              {item.iconTagline}
                            </div>
                          }
                          arrowContent={
                            <div className="rc-tooltip-arrow-inner"></div>
                          }
                        >
                          {
                            item.icon &&
                            <div>
                              <img
                                src={item.icon.mediaItemUrl}
                                alt={item.icon.altText}
                              />
                            </div>
                          }
                        </Tooltip>
                      );
                    })}
                  </div>
                  <span className="special-text">{module.tagline}</span> </div>
                <div className="btn-custom mt-30">
                  {module.button &&
                    <Link to={module.button.url} className="btn greenOutlinebtn smallBtn">{module.button.title}</Link>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreCard;
export const AccommodationExploreCardFragment = graphql`
  fragment AccommodationExploreCardFragment on WpAccommodation_Roomscontent_StaysContent_ExploreCard {
    # content
    id
    title
    tagline
    heading
    fieldGroupName
    extraClass
    descriptions
    button {
      url
      title
      target
    }
    amenities {
      iconTagline
      icon {
        altText
        uri
        mediaItemUrl
      }
    }
    image {
      altText
      uri
      mediaItemUrl
      gatsbyImage(width: 1000, formats: WEBP, placeholder: BLURRED)
    }
  }
`;
