import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import { Link } from "gatsby";
import { OffersListingData } from "./OffersListingData";

const Offers = ({ module, i }) => {
  const blog_data = OffersListingData();
  const blog = blog_data.posts.edges;
  const handleNextClick = () => {
    const swiper = document.querySelector(".contentSlider").swiper;
    swiper.slideNext();
  };
  const handlePrevClick = () => {
    const swiper = document.querySelector(".contentSlider").swiper;
    swiper.slidePrev();
  };
  return (
    <>
      <section
        className={`content-with-slider half-content-slider lg:py-60 py-30 ${
          module.extraClass === "" ? "" : module.extraClass
        }`}
      >
        <div className="container-fluid !pr-0">
          <div className="lg:flex items-center justify-between">
            <div className="lg:w-4/12">
              <div className="content mb-30 lg:mb-0 fade-ani">
                <div className="title-green mb-30">
                  <h2>{module.heading}</h2>{" "}
                </div>
                <p>{parse(module.descriptions)}</p>
                <div className="swiper-arrow">
                  <a
                    className="button-prev cursor-pointer"
                    onClick={handlePrevClick}
                  >
                    <img src="../../images/swiper-next.svg" alt="" />
                  </a>
                  <a
                    className="button-next cursor-pointer"
                    onClick={handleNextClick}
                  >
                    <img src="../../images/swiper-next.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="lg:w-7/12">
              <Swiper
                slidesPerView={1.2}
                spaceBetween={30}
                speed={1000}
                loop={true}
                autoplay={{ delay: 5000 }}
                breakpoints={{
                  0: { spaceBetween: 15 },
                  768: { slidesPerView: 4, spaceBetween: 20 },
                  1024: { spaceBetween: 30 },
                  2300: { slidesPerView: 2.5 },
                }}
                className="contentSlider"
              >
                {blog.map((item, i) => {
                  return (
                    <SwiperSlide>
                      {item.node.featuredImage ? (
                        <Link to={item.node.uri}>
                          <GatsbyImage
                            image={getImage(item.node.featuredImage.node)}
                            alt={item.node.featuredImage.node.altText}
                          ></GatsbyImage>
                        </Link>
                      ) : (
                        <StaticImage
                          src="../../images/placeholder.jpg"
                          alt=""
                        ></StaticImage>
                      )}
                      <div className="content-wrapper pt-25 pb-10">
                        <div className="title-black">
                          <Link to={item.node.uri}>
                            <h4 className="mb-15">{item.node.title}</h4>
                          </Link>
                          {item.node.excerpt && parse(item.node.excerpt)}
                        </div>
                        <Link to={item.node.uri} className="mt-30 green-btn">
                          Explore
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Offers;
export const OfferSliderFragment = graphql`
  fragment OfferSliderFragment on WpAccommodation_Roomscontent_StaysContent_Offers {
    # content
    id
    descriptions
    extraClass
    fieldGroupName
    heading
  }
`;
