import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { TestimonialData } from "./TestimonialData";
import { Navigation, Pagination } from "swiper";
import parse from "html-react-parser";

const TestimonialsListing = ({ module, i }) => {
  const allTestimonial = TestimonialData();
  const data = allTestimonial.posts.edges;
  return (
    <>
      <section className="testimonialSlider bg-greenColor text-snowWhite font-body text-20 lg:text-25 leading-40 text-center lg:pt-130 py-50 lg:pb-60 lg:mt-60 mt-30">
        <div className="container-fluid">
          <Swiper slidesPerView={1} spaceBetween={30} loop={true} pagination={{clickable: true,}} modules={[Pagination]} className="testimonialSwiper">
            {data.length > 0 &&
              data.map((item, i) => {
                return (
                  <SwiperSlide>
                    <div className="content fade-ani">
                      {parse(item.node.content)}
                      <span>-{item.node.title}</span> 
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default TestimonialsListing;
export const AccommodationTestimonialSliderFragment = graphql`
  fragment AccommodationTestimonialSliderFragment on WpAccommodation_Roomscontent_StaysContent_TestimonialsListing {
    # content
    id
  }
`;