import React from "react";
import { graphql } from "gatsby";
import FullImageSlider from "./FullImageSlider";
import TestimonialsListing from "./TestimonialsListing";
import DiscoverCard from "./DiscoverCard";
import AccordionList from "./AccordionList";
import Offers from "./Offers";
import AccommodationFutures from "./AccommodationFutures";
import ImageContent from "./ImageContent";
import ExploreCard from "./ExploreCard";
import ImageContentWithTab from "./ImageContentWithTab";

const AccommodationsModules = (props) => {
  const modules = props.modules.staysContent;
  const components = {
    TestimonialsListing,
    FullImageSlider,
    DiscoverCard,
    AccordionList,
    Offers,
    ImageContent,
    ExploreCard,
    ImageContentWithTab,
  };

  return (
    <>
      <AccommodationFutures data={props.modules.accommodationFeatures} />
      <>
        {modules
          ? modules.map((module, i) => {
              const moduleName = module.__typename.replace(
                "WpAccommodation_Roomscontent_StaysContent_",
                ""
              );
              return (
                components[moduleName] &&
                React.createElement(components[moduleName], {
                  key: i,
                  module,
                  i,
                  postData: props.postData,
                })
              );
            })
          : null}
      </>
    </>
  );
};

export default AccommodationsModules;
export const AccommodationsModulesFragment = graphql`
  fragment AccommodationsModulesFragment on WpAccommodation {
    roomsContent {
      accommodationFeatures {
        heading
        fieldGroupName
        image {
          altText
          mediaItemUrl
          uri
        }
      }
      accommodationBannerVideo
      staysContent {
        __typename
        ...AccommodationTestimonialSliderFragment
        ...AccommodationFullImageSliderFragment
        ...AccommodationDiscoverCardFragment
        ...AccommodationAccordionListFragment
        ...OfferSliderFragment
        ...AccommodationImageContentFragment
        ...AccommodationExploreCardFragment
        ...AccommodationImageContentWithTabFragment
      }
    }
  }
`;