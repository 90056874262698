import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Modal from 'react-modal';
import { EffectFade, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

const FullImageSlider = ({ module }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  function openModal(data) {
    document.body.classList.add('modal-open');
    setIsOpen(true);
  }

  function closeModal() {
    document.body.classList.remove('modal-open');
    setIsOpen(false);
  }
  function openModal2(data) {
    document.body.classList.add('modal-open');
    setIsOpen2(true);
  }

  function closeModal2() {
    document.body.classList.remove('modal-open');
    setIsOpen2(false);
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // width: "930px",
      padding: '0',
      border: '0',
      borderRadius: '0',
    },
    overlay: {
      zIndex: '99',
    },
  };
  return (
    <>
      <section className="full-img-slider lg:py-120 py-60 lg:my-60 my-30 bg-lightGreen bg-opacity-4">
        <div className="container-fluid pr-0">
          <Swiper
            spaceBetween={30}
            speed={1000}
            loop={false}
            pagination={{
              type: 'fraction',
              formatFractionCurrent: function (number) {
                return ('' + number).slice(-2);
              },
              formatFractionTotal: function (number) {
                return ('' + number).slice(-2);
              },
              renderFraction: function (currentClass, totalClass) {
                return (
                  '<span class="' +
                  currentClass +
                  '"></span>' +
                  '' +
                  '<span class="' +
                  totalClass +
                  '"></span>'
                );
              },
            }}
            navigation={true}
            modules={[Navigation, Pagination]}
            className="singleSlider"
          >
            {module.fullImageSliders.map((item, i) => {
              return (
                <div key={i}>
                  <SwiperSlide>
                    <div className="image-wrapper">
                      {item.image ? (
                        <GatsbyImage
                          image={getImage(item.image)}
                          alt={item.image.altText}
                        ></GatsbyImage>
                      ) : (
                        <StaticImage
                          src="../../images/placeholder.jpg"
                          alt=""
                        ></StaticImage>
                      )}
                      {item.video ? (
                        <div className="play-video">
                          <button
                            to="/"
                            className="inline-block"
                            onClick={openModal2}
                          >
                            <img src="../../images/play.svg" />
                          </button>
                          <Modal
                            isOpen={modalIsOpen2}
                            style={customStyles}
                            onRequestClose={closeModal2}
                            contentLabel="Example Modal"
                          >
                            <div className="video-modal-popup">
                              <button
                                onClick={closeModal2}
                                className="pr-30 pt-30"
                              >
                                <img src="/images/modal-close.svg" />
                              </button>
                              <div className="flex flex-wrap items-center">
                                <div className="lg:w-12/12 w-full h-full">
                                  <div className="modal-video">
                                    <div className="modal-banner-video">
                                      <iframe
                                        src={item.video}
                                        title="YouTube video player"
                                        showinfo="0"
                                        autoplay="1"
                                        frameborder="0"
                                        controls="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                      ></iframe>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modal>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <span className="subTitle">{item.title}</span>
                  </SwiperSlide>
                </div>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default FullImageSlider;
export const AccommodationFullImageSliderFragment = graphql`
  fragment AccommodationFullImageSliderFragment on WpAccommodation_Roomscontent_StaysContent_FullImageSlider {
    # content
    id
    heading
    extraClass
    fieldGroupName
    fullImageSliders {
      title
      video
      image {
        altText
        mediaItemUrl
        gatsbyImage(
          width: 1000
          formats: WEBP
          layout: FULL_WIDTH
          placeholder: BLURRED
        )
      }
    }
  }
`;
