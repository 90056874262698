import React, { useEffect,useState  } from 'react';
import { Link } from "gatsby";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Modal from 'react-modal';

const ImageContentWithTab = ({ module }) => {
  const [tabOption, setTabOption] = useState(module.tabWithContent[0].tabName);
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpen2, setIsOpen2] = React.useState(false);
    const [modalIsOpen3, setIsOpen3] = React.useState(false);
      function openModal(data) {
        document.body.classList.add("modal-open");
        setIsOpen(true);
      }
      function afterOpenModal3() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }
      function closeModal() {
        document.body.classList.remove("modal-open");
        setIsOpen(false);
      }
      function openModal2(data) {
        document.body.classList.add("modal-open");
        setIsOpen2(true);   
      }
    
      function closeModal2() {
        document.body.classList.remove("modal-open");
        setIsOpen2(false);
    }
    function openModal3(data) {
        document.body.classList.add("modal-open");
        setIsOpen3(true);   
      }
    
      function closeModal3() {
        document.body.classList.remove("modal-open");
        setIsOpen3(false);
      }
      const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          // width: "930px",
          padding: "0",
          border: "0",
          borderRadius: "0",
          },
          overlay: {
              zIndex:"99",
          },
    };
    const customStyles3 = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            border: '0',
            borderRadius: '0',
            padding: '50px 35px',
            maxWidth:'100%',
        },
        overlay: {
            zIndex:"99",
        },
    };
  return (
    <>
      <section className='zigzag lg:py-60 py-30'>
              <div className='container-fluid'>
                  <div className='flex flex-wrap items-start'>
                      <div className={`lg:w-6/12 w-full ${module.imagePosition === "left" ? "lg:order-1" : "lg:order-2"}`}>
                          <GatsbyImage
                            image={getImage(module.image)}
                            alt={module.image.altText}
                            className='w-100'
                          ></GatsbyImage>
                      </div>
                      <div className={`lg:w-6/12 w-full ${module.imagePosition === "left" ? "lg:order-2" : ""}`}>
                          <div className='zigzag-content lg:max-w-490 lg:mx-auto pt-30 lg:py-30 lg:px-19 fade-ani'>
                              <span className='text-greenColor text-12 capitalize leading-12 font-400'>{module.preHeading}</span>
                              <div className='title-black mb-30 mt-30'>
                                 <h3>{module.heading}</h3>
                                </div>
                                <ul className='tabs flex gap-30 mb-30'>
                                  {module.tabWithContent.map((item, i) => {
                                    return (
                                        <li
                                          onClick={() => {
                                            setTabOption(item.tabName);
                                          }}
                                          className={`tab-link ${
                                            tabOption === item.tabName ? "tab-current" : ""
                                          }`}
                                          data-tab={item.tabName}
                                        >
                                          {item.tabName}
                                        </li>
                                        );
                                      })}
                                    </ul>
                                <div className='content global-list'>
                                {module.tabWithContent.map((item, i) => {
                                  return (
                                    <div id={item.tabName} className={` ${
                                      tabOption === item.tabName ? "tab-current" : "hidden"
                                    }`}>
                                      {parse(item.tabContent)}
                                      <span className='special-text'>{item.tabTagline}</span>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className='btn-custom mt-30'>
                                {module.bookingButton &&
                                    <Link to={module.bookingButton.url} className='btn greenOutlinebtn mdbtn'>{module.bookingButton.title}</Link> 
                                  }
                                    <button className='btn green-btn' onClick={openModal3}>View Full Rates</button>
                                    <Modal 
                                    isOpen={modalIsOpen3}
                                    onRequestClose={closeModal3}
                                    style={customStyles3}
                                >
                                    <button className='absolute right-60' onClick={closeModal3}>
                                    <img  src="./../../images/close.png"/></button> 
                                    <div className='rates-modal'>
                                        <div className='modal-title lg:pt-50 pt-20 lg:pl-30 pl-0 border-b-1 border-silver pb-30'>
                                            {module.popupPreHeading &&
                                              <span class="text-greenColor leading-11 text-12 capitalize font-400">{module.popupPreHeading}</span>
                                            }
                                            {module.popupHeading &&
                                              <h2 className='pt-15 text-30 lg:text-38'>{module.popupHeading}</h2>
                                            }
                                          <Link to='/rates' className='btn greenOutlinebtn mdbtn mt-10' onClick={closeModal3}>View All Rates</Link>
                                        </div>
                                        <div className='modal-content'>
                                            <div className='lg:grid lg:grid-cols-5 grid grid-cols-2 gap-10 lg:gap-30 md:gap-0 lg:px-30 px-0 modal-m-border'>
                                              {module.currentRate.map((itemrate, i) => {
                                                return (
                                                  <div className='modal-col pt-20'>
                                                      <h6 className='text-14 leading-20 text-greenColor text-opacity-70 font-400 mb-20'>{itemrate.currentRateDate}</h6>
                                                      <h4 className='text-brown tracking-003em capitalize font-400text-charcol  text-20 leading-24 lg:text-24 lg:leading-35 font-400'>{itemrate.currentFullRate}</h4>
                                                     <span className='text-12 font-body tracking-003em lowercase font-400 text-charcol'>{itemrate.currentRateTagline}</span>
                                                  </div>
                                                  );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                </div>
                          </div>
                      </div>
                  </div> 
             </div>
          </section>
    </>
  );
};

export default ImageContentWithTab;
export const AccommodationImageContentWithTabFragment = graphql`
  fragment AccommodationImageContentWithTabFragment on WpAccommodation_Roomscontent_StaysContent_ImageContentWithTab {
    # content
    id
    preHeading
    imageSize
    imagePosition
    imageBorderStyle
    heading
    fieldGroupName
    extraClass
    popupPreHeading
    popupHeading
    buttonStyle
    button {
      url
      title
      target
    }
    bookingButton {
      url
      title
      target
    }
    image {
      altText
      uri
      mediaItemUrl
      gatsbyImage(width: 1000, formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
    }
    tabWithContent {
      tabTagline
      tabName
      tabContent
      fieldGroupName
    }
    currentRate {
      currentFullRate
      currentRateDate
      currentRateTagline
      fieldGroupName
    }
  }
`;
