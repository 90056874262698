import { graphql, useStaticQuery } from "gatsby";

export const TestimonialData = () => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query WpAccommodationTestimonials {
      posts: allWpTestimonial {
        edges {
            node {
              title
              content
            }
          }
        }
    }
  `);
  return data
};

