import { graphql, useStaticQuery } from "gatsby";

export const OffersListingData = () => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query WpAccommodationOffer {
      posts: allWpOffer(sort: { fields: [id], order: [DESC] },limit: 6)  {
        edges {
          node {
           id
            title
            uri
            excerpt
            featuredImage {
              node {
                mediaItemUrl
                altText
                gatsbyImage(width: 1000, formats: WEBP, layout:FULL_WIDTH, placeholder: BLURRED)
                title
                uri
              }
            }
          }
        }
      }
    }
  `);  return data;
};
