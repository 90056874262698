import { Link, graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import parse from 'html-react-parser';
import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

const Banner = ({ postData, i }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal(data) {
    document.body.classList.add('video-modal-open');
    setIsOpen(true);
  }

  function closeModal() {
    document.body.classList.remove('video-modal-open');
    setIsOpen(false);
  }

  const preHeadings = useStaticQuery(graphql`
    query {
      wp {
        fluxDnaSettings {
          fluxDna {
            taglines {
              tagline
              fieldGroupName
            }
          }
        }
      }
    }
  `);
  const preHeadingsList = preHeadings.wp.fluxDnaSettings.fluxDna;
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0',
      border: '0',
      borderRadius: '0',
    },
    overlay: {
      zIndex: '99',
    },
  };

  return (
    <>
      <section className="hero-section">
        <div className="h-100 w-100 min-h-700 relative">
          <GatsbyImage
            className="h-100pr w-100 object-cover"
            fadeIn={false}
            loading="eager"
            image={getImage(postData.image)}
          />
          <div className="absolute top-0 left-0 right-0 bottom-0 flex align-middle items-end justify-center p-15">
            <div className="text-center content-text pb-150">
              {postData.bannerVideo && (
                <Button
                  className="inline-block play-icon mb-40"
                  onClick={openModal}
                >
                  <img src="../../images/play-icon.svg" />
                </Button>
              )}
              <div className="fade-ani">
                <h1 className="text-white max-w-515 mb-10 mx-auto">
                  {postData.title}
                </h1>
                <p className="text-white">
                  {postData.excerpt && parse(postData.excerpt)}
                </p>
              </div>
              {postData.bannerVideo && (
                <Modal
                  isOpen={modalIsOpen}
                  style={customStyles}
                  onRequestClose={closeModal}
                  contentLabel="Example Modal"
                >
                  <div className="video-modal-popup">
                    <button onClick={closeModal} className="pr-30 pt-30">
                      <img src="/images/modal-close.svg" />
                    </button>
                    <div className="flex flex-wrap items-center">
                      <div className="lg:w-12/12 w-full h-full">
                        <div className="modal-video">
                          <div className="modal-banner-video">
                            <iframe
                              src={postData.bannerVideo}
                              autoplay="1"
                              controls="0"
                              showinfo="0"
                              frameborder="0"
                              allowfullscreen
                              title="YouTube video player"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
export const AccommodationBannerFragment = graphql`
  fragment AccommodationBannerFragment on WpAccommodation_Roomscontent_StaysContent_Banner {
    # content
    id
    extraClass
  }
`;
