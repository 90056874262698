import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const DiscoverCard = ({ module, i }) => {
  return (
    <>
      <section
        className={`blog-card lg:py-60 py-30 ${module.extraClass === "" ? "" : module.extraClass
          }`}
      >
        <div className="container-fluid">
          <div className="title-green text-center fade-ani">
            <h2 className="mb-10">{module.heading}</h2>
            {parse(module.descriptions)}
          </div>
          <div className="blog-card-grid mt-30 lg:mt-50">
            <div className="flex flex-wrap md:flex-nowrap gap-30 md:gap-0 mx-minus15">
              {module.discoverCards.length > 0 &&
                module.discoverCards.map((item, i) => {
                  return (
                    <div className="lg:w-4/12 md:w-4/12 w-full px-15">
                      <div className="card-wrapper ">
                        <div className="">
                          <div className="image-wrapper mb-20 lg:mb-40">
                            {item.image ? (
                              <Link
                                target={item.button.target}
                                to={item.button.url}
                              >

                                <GatsbyImage
                                  image={getImage(item.image)}
                                  alt={item.image.altText}
                                ></GatsbyImage>
                              </Link>
                            ) : (
                              <StaticImage
                                src="../images/placeholder.jpg"
                                alt=""
                              ></StaticImage>
                            )}
                          </div>
                          <div className="fade-ani">
                            <div className="title-black mb-15">
                              <Link to={item.button.url} target={item.button.target}>
                                <h4>{item.heading}</h4>
                              </Link>
                            </div>
                            <div className="content mb-30 max-w-85pr">
                              {parse(item.descriptions)}
                            </div>
                            <Link
                              to={item.button.url}
                              target={item.button.target}
                              className="green-btn"
                            >
                              {item.button.title}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DiscoverCard;
export const AccommodationDiscoverCardFragment = graphql`
  fragment AccommodationDiscoverCardFragment on WpAccommodation_Roomscontent_StaysContent_DiscoverCard {
    # content
    id
    id
    descriptions
    heading
    extraClass
    discoverCards {
      descriptions
      heading
      image {
        altText
        mediaItemUrl
        gatsbyImage(width: 1000, formats: WEBP, placeholder: BLURRED)
      }
      button {
        target
        title
        url
      }
    }
  }
`;
