import React from "react";
const AccommodationFutures = ({ data, i }) => {
  return (
    <>
      {data && (
        <section className='icon-list mt:30 lg:mt-60 py-30 lg:py-60'>
          <div className='container-fluid'>
            <div className='grid icon-grid'>
              {data &&
                data.map((item, i) => {
                  return (
                    <div key={i} className='icon-content text-center'> 
                      <img src={item.image.mediaItemUrl} /> 
                      <span>{item.heading}</span> 
                    </div>
                  );
              })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default AccommodationFutures;
