import React, { useEffect,useState  } from 'react';
import { Link } from "gatsby";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Modal from 'react-modal';

const ImageContent = ({ module,postData }) => {

  const [modalIsOpen4, setIsOpen4] = React.useState(false);
  function openModal4(data) {
    document.body.classList.add("modal-open");
    setIsOpen4(true);   
  }

  function closeModal4() {
    document.body.classList.remove("modal-open");
    setIsOpen4(false);
  }
  const customStyles4 = {
      content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#FFFFFF',
          border: '0',
          borderRadius: '0',
          padding: '50px 35px',
          maxWidth:'100%',
      },
      overlay: {
          zIndex:"99",
      },
  };

  return (
    <>
      <section
        className={`zigzag lg:py-60 py-30 ${
          module.extraClass === "" ? "" : module.extraClass
        }`}
      >
        <div className="container-fluid">
          <div className="flex flex-wrap items-center">
            <div
              className={`lg:w-6/12 w-full ${
                module.imagePosition === "left" ? "lg:order-2" : ""
              }`}
            >
              <div className="zigzag-content lg:max-w-450 lg:mx-auto py-30 lg:px-19 fade-ani">
                <span className="text-greenColor text-12 capitalize leading-12 font-400">
                  {module.preHeading}
                </span>
                <div className="title-black mb-30 mt-30">
                  <h3>{module.heading}</h3>
                </div>
                <div className="content global-list">
                  {parse(module.description)}
                </div>
                <div className="btn-custom mt-30">
                  {module.bookingButton && (
                    <Link
                      to={module.bookingButton.url}
                      target={module.button.target}
                      className="btn greenOutlinebtn mdbtn"
                    >
                      {module.bookingButton.title}
                    </Link>
                  )}
                  {module.button && module.button.url !== "#rates-modal" && (
                    <Link
                      to={module.button.url}
                      state={{ room: postData.title }}
                      className="btn green-btn"
                    >
                      {module.button.title}
                    </Link>
                  )}
                  {module.button && module.button.url === "#rates-modal" && (
                    <>
                    <button className='btn green-btn' onClick={openModal4}>View Full Rates</button>
                    <Modal 
                    isOpen={modalIsOpen4}
                    onRequestClose={closeModal4}
                    style={customStyles4}
                    >
                        <button className='absolute right-60' onClick={closeModal4}>
                        <img  src="./../../images/close.png"/></button> 
                        <div className='rates-modal'>
                            <div className='modal-title lg:pt-50 pt-20 lg:pl-30 pl-0 border-b-1 border-silver pb-30'>
                                {module.popupPreHeading &&
                                  <span class="text-greenColor leading-11 text-12 capitalize font-400">{module.popupPreHeading}</span>
                                }
                                {module.popupHeading &&
                                  <h2 className='pt-15 text-30 lg:text-38'>{module.popupHeading}</h2>
                                }
                              <Link to='/rates' className='btn greenOutlinebtn mdbtn mt-10' onClick={closeModal4}>View All Rates</Link>
                            </div>
                            <div className='modal-content'>
                                <div className='lg:grid lg:grid-cols-5 grid grid-cols-2 gap-10 lg:gap-30 md:gap-0 lg:px-30 px-0 modal-m-border'>
                                  {module.currentRate.map((itemrate, i) => {
                                    return (
                                      <div className='modal-col pt-20'>
                                          <h6 className='text-14 leading-20 text-greenColor text-opacity-70 font-400 mb-20'>{itemrate.currentRateDate}</h6>
                                          <h4 className='text-brown tracking-003em capitalize font-400text-charcol  text-20 leading-24 lg:text-24 lg:leading-35 font-400'>{itemrate.currentFullRate}</h4>
                                        <span className='text-12 font-body tracking-003em lowercase font-400 text-charcol'>{itemrate.currentRateTagline}</span>
                                      </div>
                                      );
                                    })}
                                </div>
                            </div>
                        </div>
                    </Modal>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div
              className={`lg:w-6/12 w-full ${
                module.imagePosition === "right" ? "" : ""
              }`}
            >
              <GatsbyImage
                image={getImage(module.image)}
                alt={module.image.altText}
              ></GatsbyImage>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageContent;
export const AccommodationImageContentFragment = graphql`
  fragment AccommodationImageContentFragment on WpAccommodation_Roomscontent_StaysContent_ImageContent {
    # content
    id
    heading
    preHeading
    description
    extraClass
    popupPreHeading
    popupHeading
    fieldGroupName
    buttonStyle
    imageSize
    imagePosition
    imageBorderStyle
    image {
      altText
      uri
      mediaItemUrl
      gatsbyImage(width: 1000, formats: WEBP, placeholder: BLURRED)
    }
    button {
      url
      title
      target
    }
    bookingButton {
      url
      title
      target
    }
    currentRate {
      currentFullRate
      currentRateDate
      currentRateTagline
      fieldGroupName
    }
  }
`;
